"use client";

import { useEffect, useRef } from "react";
import { Button } from "./Button";
import { Container } from "./Container";
import { Logo } from "./Logo";
import { NavLink } from "./NavLink";

export type HeaderProps = {
  variant: "light" | "dark";
  url: URL;
};

export function Header({ variant, url }: HeaderProps) {
  return (
    <header className="py-6 relative z-50 transition-all">
      <Container>
        <nav className="relative flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <a href="/" aria-label="Home">
              <Logo variant={variant} className="h-10 w-auto" />
            </a>
            <div className="hidden md:flex md:gap-x-6">
              <NavLink variant={variant} href="/" active={url.pathname == "/"}>
                For Organizations
              </NavLink>
              <NavLink
                variant={variant}
                href="/support"
                active={url.pathname.startsWith("/support")}
              >
                Support
              </NavLink>
              <NavLink
                variant={variant}
                href="/contact"
                active={url.pathname.startsWith("/contact")}
              >
                Contact
              </NavLink>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <div className="hidden md:block">
              <NavLink variant={variant} href="https://hub.ilpapp.com/#/login">
                Sign in
              </NavLink>
            </div>
            <Button
              href="https://hub.ilpapp.com/#/register"
              color={variant == "dark" ? "white" : "primary"}
            >
              <span>
                Get started <span className="hidden lg:inline">today</span>
              </span>
            </Button>
          </div>
        </nav>
      </Container>
    </header>
  );
}
